import instance from './instance'
import { createBy, updateBy } from 'utils/apiHelper'

const client = instance
export const exportAudience = async (data) => {
  try {
  
    const response = await client.post('/audienceExport/getAudienceExports', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
  
}
