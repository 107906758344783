import client from './instance'
import client2 from './instanceNoLodding'

export const encrypt = async (data) => {
  try {
    const response = await client.post('/care-message-notification/encrypt', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};
export const unread = async (data) => {
  try {
    const response = await client.post('/care-message-notification/unread');

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getNotiByActive = async (data) => {
  try {
    const response = await client2.post('/care-message-notification/getNotiByActive', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const readNoti = async (data) => {
  try {
    const response = await client2.post('/care-message-notification/readNoti', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const deleteNoti = async (data) => {
  try {
    const response = await client2.post('/care-message-notification/deleteNoti', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export const updateNoti = async (data) => {
  try {
    const response = await client2.post('/care-message-notification/updateNoti', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
