import client from './instance'
 
export const getSchedulerTouchpointActivityResult = async (data) => {
  try {
    const response = await client.post('/scheduler-touchpoint-activity-result/getSchedulerTouchpointActivityResult', data);
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getResultData = async (data) => {
  try {
    const response = await client.post('/scheduler-touchpoint-activity-result/getResultData', data);
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const downloadResultData = async (data) => {
  try {
    const response = await client.post('/scheduler-touchpoint-activity-result/downloadResultData', data);
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getTouchpoints = async (data) => {
  try {
    const response = await client.post('/touchpoint/getTouchpoints', data);

    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
};

export const getActivity = async (data) => {
  try {
    const response = await client.post('/touchpoint/getActivity', { data });

    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getSchedulerStatus = async (data) => {
  try {
    const response = await client.post('/touchpoint/getSchedulerStatus', data);
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}
