import store from 'store'
import axios from 'axios'
import config from './config'
import Swal from 'sweetalert2'

const { auth } = store.getState()
const instance  = axios.create({
    baseURL: config.API_BASE_URL,
})
let loadingTimeout;
let numberOfAjaxCAllPending = 0;
instance.interceptors.request.use(
  (config) => {
      const token = auth.accessToken;
      if(token){
         //config.headers.Authorization =  token ? `Bearer ${token}` : '';
        config.headers['x-access-token'] = token;
      }
      loadingTimeout = setTimeout(() => {
        if(numberOfAjaxCAllPending==1){
          Swal.fire({
            title: ' ',
            html: `<div class='sweetalert-title justify-content-end'  >Please wait ...</div>`,
            allowOutsideClick: false,
            heightAuto: false,
            customClass: {
              text: 'sweetalert-title justify-content-end mt-2 p-0',
              title: 'sweetalert-title mt-2 p-0',
              // popup: 'sweetalert-popup'
            },
            didOpen: () => {
                Swal.showLoading();
            }
          });
        }
      }, 1000); // 1 seconds
      numberOfAjaxCAllPending++;
      return config;
  }, function (error) {
    numberOfAjaxCAllPending--;
    if(numberOfAjaxCAllPending==0){
      Swal.close();
    }
    clearTimeout(loadingTimeout);
    return Promise.reject(error);
  } 
  
)

instance.interceptors.response.use(
  (response) => {
      numberOfAjaxCAllPending--;
      if(numberOfAjaxCAllPending==0){
        Swal.close();
      }
      clearTimeout(loadingTimeout);
      return response;
  },
  (error) => {
      if (error.response) {
          console.error('Response error:', error.response);
          if (error.response?.status === 401) {
            localStorage.clear()
            redirectLoginCas()
          } 
      } else {
          console.error('Network error:', error);
      }
      // return error;
      numberOfAjaxCAllPending--;
      if(numberOfAjaxCAllPending==0){
        Swal.close();
      }
      clearTimeout(loadingTimeout);
      return Promise.reject(error);
  }
);

const redirectLoginCas = () => {
  const casUrl = process.env.REACT_APP_SC2_CAS_URL ? process.env.REACT_APP_SC2_CAS_URL : 'https://sc2-dev.tmap-em.toyota-asia.com'
  const loginUrl = casUrl+"/cas/login?service="+encodeURIComponent(window.location.origin)+"%2Flogin%2F&locale=th_TH&un="
  window.location.replace(loginUrl)
}

export default instance;
