import client from './instance'
 
export const createShortUrl = async (data) => {
  try {
    const response = await client.post('/shorted-url-activities/createShortUrl', data);
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const updateShortUrl = async (data) => {
    try {
      const response = await client.post('/shorted-url-activities/updateShortUrl', data);
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

export const getShortUrl = async (data) => {
    try {
      const response = await client.post('/shorted-url-activities/getShortUrl', data);
      return response.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }