import client from './instance'
import { createBy, updateBy } from 'utils/apiHelper'

export const getRoles = async (data) => {
  try {
    const response = await client.post('/role/getRoles', data)

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getRoleBy = async (data) => {
  try {
    const response = await client.post('/role/getRoleBy', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getRoleById = async (uuid) => {
  try {
    const response = await client.post('/role/getRoleById', uuid);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export const getRolePermissionByUuid = async (uuid) => {
  try {
    const response = await client.post('/role/getRolePermissionByUuid', uuid);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export const createRole = async (data) => {
  try {
    const response = await client.post('/role/createRole', createBy(data));

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export const updateRole = async (data) => {
  try {
    const response = await client.post('/role/updateRole', updateBy(data));

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}

export const activeRoles = async (data) => {
  try {
    const response = await client.post('/role/activeRoles', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}
export const inActiveRoles = async (data) => {
  try {
    const response = await client.post('/role/inActiveRoles', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export const deleteRoles = async (data) => {
  try {
    const response = await client.post('/role/deleteRoles', data);

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
}