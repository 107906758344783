import client from './instance'
import { createBy, updateBy } from 'utils/apiHelper'
import { isFile } from 'utils/miscHelper'
import client2 from './instanceNoLodding'



export const getCmContents = async (data) => {
  try {
    const response = await client.post('/cm-content/getCmContents', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getCmContentsByCodeOrName = async (data) => {
  try {
    const response = await client.post('/cm-content/getCmContentsByCodeOrName', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const generateLastCode = async (code) => {
  try {
    const response = await client.post('/cm-content/generateLastCode', { code });
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getCmContentById = async (id) => {
  try {
    const response = await client2.post('/cm-content/getCmContentById', { id });
    console.warn('responseresponse=',response);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getCmContentBy = async (data) => {
  try {
    const response = await client.post('/cm-content/getCmContentBy', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}
export const getCmContentCareMessage = async (data) => {
  try {
    const response = await client.post('/cm-content/getCmContentCareMessage', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

const getFileSizeFromUrl = async (url) => {
  try {
    const response = await fetch(url); // Send a HEAD request to get headers only
    if (response.ok) {
      const size = response.headers.get('Content-Length');
      return parseInt(size, 10);
    } else {
      console.error('Failed to fetch URL:', url);
      return 0;
    }
  } catch (error) {
    console.error('Error fetching URL:', url, error);
    return 0;
  }
};

export const createCmContent = async (data) => {
  try {
    let size = 0;
    let maxSize = 10 * 1024 * 1024
    const formData = new FormData();
    formData.append("cmContent", JSON.stringify(createBy(data)))
    // if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody[0].thumbnailFileUrl)) {
    //   formData.append("T-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody[0].thumbnailFileUrl)
    // }
    // if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody[0].videoFileUrl)) {
    //   formData.append("T-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody[0].videoFileUrl)
    // }

    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody[0].thumbnailFileUrl)) {
      formData.append("C-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody[0].thumbnailFileUrl)
      let cFile = data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody[0].thumbnailFileUrl
      size += cFile?.size ? cFile.size : 0;
    }
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody[0].videoFileUrl)) {
      formData.append("C-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody[0].videoFileUrl)
      let cFile = data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody[0].videoFileUrl
      size += cFile?.size ? cFile.size : 0;
    }

    //console.log("backend===>",data)
    
    data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody.map((item,index)=>{
      console.log(item)
      if(isFile(item.lineImageUrl) && item.lineType === "I"){
        formData.append(`L-lineImageUrl-${item.lineOrderNumber}`, item.lineImageUrl)
        let lFile = item.lineImageUrl
        size += lFile?.size ? lFile.size : 0;
      }
    })
    data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody.map((item,index)=>{
      console.log(item)
      if(isFile(item.lineVideoPreviewUrl) && item.lineType === "V"){
        formData.append(`L-lineVideoPreviewUrl-${item.lineOrderNumber}`, item.lineVideoPreviewUrl)
        let lFile = item.lineVideoPreviewUrl
        size += lFile?.size ? lFile.size : 0;
      }
    })
    data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody.map((item,index)=>{
      console.log(item)
      if(isFile(item.lineVideoUrl) && item.lineType === "V"){
        formData.append(`L-lineVideoUrl-${item.lineOrderNumber}`, item.lineVideoUrl)
        let lFile = item.lineVideoUrl
        size += lFile?.size ? lFile.size : 0;
      }
    })

    //console.log(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')], " : ",data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')]?.cmContentChannelInfoBody[0]?.cmContentEmailAttachFiles)
    if(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]
      && data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody.length > 0){
        data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody.map((item)=>{
          if(item?.lineType && item.lineType === "C"){
            const card = item.cmContentLineCard;
            console.log("ok")
            Object.keys(card).forEach(key => {
              console.log("key---->",card[key].lineCardImageUrl)
              if(isFile(card[key].lineCardImageUrl)){
                formData.append(`L-cardFile-${item.lineOrderNumber}-${card[key].lineCardOrderNumber}`, card[key].lineCardImageUrl)
                let lFile = card[key].lineCardImageUrl
                size += lFile?.size ? lFile.size : 0;
               console.log("data---->",card[key].lineCardImageUrl)
              }
          });
          }
        })
        
    }

    let indexEmail = 0;
    //console.log(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')], " : ",data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')]?.cmContentChannelInfoBody[0]?.cmContentEmailAttachFiles)
    if(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')]
      && data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')]?.cmContentChannelInfoBody[0]?.cmContentEmailAttachFiles){
        const emailData = data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')]?.cmContentChannelInfoBody[0]?.cmContentEmailAttachFiles
        Object.keys(emailData).forEach(key => {
          //console.log("data---->",emailData[key])
          if(emailData[key]?.fileUrl?.name){
            formData.append(`E-file${indexEmail}`, emailData[key].fileUrl)
            let eFile = emailData[key].fileUrl
            size += eFile?.size ? eFile.size : 0;
           // console.log("data---->",emailData[key])
           indexEmail++;
          }
      });
    }


    if (data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoButton.length) {
      const cmContentChannelInfoButton = data.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoButton
      for (const i in cmContentChannelInfoButton) {
        if (isFile(cmContentChannelInfoButton[i].logoFileUrl)) {
          formData.append(`A-${i}-logoFileUrl`, cmContentChannelInfoButton[i].logoFileUrl)
          let aFile = cmContentChannelInfoButton[i].logoFileUrl
          size += aFile?.size ? aFile.size : 0;
        }
      }
    }
    console.log("Size create on control==>", size , maxSize)
    if(size > maxSize){
      return;
    }
    const response = await client.post('/cm-content/createCmContent', formData);
    // const response = "";
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const updateCmContent = async (data) => {
  try {
    let size = 0;
    let maxSize = 10 * 1024 * 1024
    const formData = new FormData();
    formData.append("cmContent", JSON.stringify(updateBy(data)))
    // if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody[0].thumbnailFileUrl)) {
    //   formData.append("T-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody[0].thumbnailFileUrl)
    // }
    // if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody[0].videoFileUrl)) {
    //   formData.append("T-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody[0].videoFileUrl)
    // }
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody[0].thumbnailFileUrl)) {
      formData.append("C-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody[0].thumbnailFileUrl)
      let cFile = data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody[0].thumbnailFileUrl
      size += cFile?.size ? cFile.size : 0;
    }else if(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody[0]?.thumbnailFileUrl){
      await getFileSizeFromUrl(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody[0].thumbnailFileUrl).then(fileSize => {
        size += fileSize;
      });
    }
    
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody[0].videoFileUrl)) {
      formData.append("C-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody[0].videoFileUrl)
      let cFile = data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody[0].videoFileUrl
      size += cFile?.size ? cFile.size : 0;
    }else if(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody[0]?.videoFileUrl){
       await getFileSizeFromUrl(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody[0].videoFileUrl).then(fileSize => {
        size += fileSize;
      });
    }

    if(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody?.length > 0){
      const promisesLineImage = data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody.map(async (item,index)=>{
        console.log(item)
        if(isFile(item.lineImageUrl) && item.lineType === "I"){
          formData.append(`L-lineImageUrl-${item.lineOrderNumber}`, item.lineImageUrl)
          let lFile = item.lineImageUrl
          size += lFile?.size ? lFile.size : 0;
        }else if(item?.lineImageUrl){
           await getFileSizeFromUrl(item.lineImageUrl).then(fileSize => {
            size += fileSize;
          });
        }
      })
      await Promise.all(promisesLineImage)
    }
    
    if(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody?.length > 0){
      const promisesLineVideoPreview = data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody.map(async (item,index)=>{
        console.log(item)
        if(isFile(item.lineVideoPreviewUrl) && item.lineType === "V"){
          formData.append(`L-lineVideoPreviewUrl-${item.lineOrderNumber}`, item.lineVideoPreviewUrl)
          let lFile = item.lineVideoPreviewUrl
          size += lFile?.size ? lFile.size : 0;
        }else if(item?.lineVideoPreviewUrl){
           await getFileSizeFromUrl(item.lineVideoPreviewUrl).then(fileSize => {
            size += fileSize;
          });
        }
      })
      await Promise.all(promisesLineVideoPreview)
    }
    
    if(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody?.length > 0){
      const promisesLineVideoUrl = data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody.map(async (item,index)=>{
        console.log(item)
        if(isFile(item.lineVideoUrl) && item.lineType === "V"){
          formData.append(`L-lineVideoUrl-${item.lineOrderNumber}`, item.lineVideoUrl)
          let lFile = item.lineVideoUrl
          size += lFile?.size ? lFile.size : 0;
        }else if(item?.lineVideoUrl){
           await getFileSizeFromUrl(item.lineVideoUrl).then(fileSize => {
            size += fileSize;
          });
        }
      })
      await Promise.all(promisesLineVideoUrl)
    }
    

    //console.log(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')], " : ",data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')]?.cmContentChannelInfoBody[0]?.cmContentEmailAttachFiles)
    if(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]
      && data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody.length > 0){
        const promisesLineCard = data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'L')]?.cmContentChannelInfoBody.map(async (item)=>{
          if(item?.lineType && item.lineType === "C"){
            const card = item.cmContentLineCard;
            console.log("ok")
            if(Object.keys(card)?.length > 0){
              const promisesCard = Object.keys(card).map(async key => {
                console.log("key---->",card[key].lineCardImageUrl)
                if(isFile(card[key].lineCardImageUrl)){
                  formData.append(`L-cardFile-${item.lineOrderNumber}-${card[key].lineCardOrderNumber}`, card[key].lineCardImageUrl)
                  let lFile = card[key].lineCardImageUrl
                  size += lFile?.size ? lFile.size : 0;
                 console.log("data---->",card[key].lineCardImageUrl)
                }else if(card[key]?.lineCardImageUrl){
                    await getFileSizeFromUrl(card[key].lineCardImageUrl).then(fileSize => {
                    size += fileSize;
                  });
                }
              });
              await Promise.all(promisesCard);
            }
          }
        })
        await Promise.all(promisesLineCard);
    }

    let indexEmail = 0;
    //console.log(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')], " : ",data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')]?.cmContentChannelInfoBody[0]?.cmContentEmailAttachFiles)
    if(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')]
      && data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')]?.cmContentChannelInfoBody[0]?.cmContentEmailAttachFiles){
        const emailData = data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'E')]?.cmContentChannelInfoBody[0]?.cmContentEmailAttachFiles
        console.log(emailData)
        if(Object.keys(emailData)?.length > 0){
          const promisesEmail = Object.keys(emailData).map(async key => {
            console.log("data---->",emailData[key])
            if(emailData[key]?.fileUrl?.name){
              formData.append(`E-file${indexEmail}`, emailData[key].fileUrl)
             // console.log("data---->",emailData[key])
              let eFile = emailData[key].fileUrl
              size += eFile?.size ? eFile.size : 0;
             indexEmail++;
            }else if(emailData[key]?.fileUrl){
               await getFileSizeFromUrl(emailData[key].fileUrl).then(fileSize => {
                size += fileSize;
                console.log("save 123", fileSize)
              });
            }
            
          });
          await Promise.all(promisesEmail);
        }
    }


    if (data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoButton.length) {
      const cmContentChannelInfoButton = data.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoButton
      for (const i in cmContentChannelInfoButton) {
        if (isFile(cmContentChannelInfoButton[i].logoFileUrl)) {
          formData.append(`A-${i}-logoFileUrl`, cmContentChannelInfoButton[i].logoFileUrl)
          let aFile = cmContentChannelInfoButton[i].logoFileUrl
            size += aFile?.size ? aFile.size : 0;
        }else if(cmContentChannelInfoButton[i]?.logoFileUrl){
          getFileSizeFromUrl(cmContentChannelInfoButton[i].logoFileUrl).then(fileSize => {
            size += fileSize;
          });
        }
      }
    }

    if (data.campaignImage) {
      formData.append("campaign_image", data.campaignImage)
      let campaignFile = data.campaignImage
      size += campaignFile?.size ? campaignFile.size : 0;
    }else if(data.campaignImage){
      getFileSizeFromUrl(data?.campaignImage).then(fileSize => {
        size += fileSize;
      });
    }
    console.log("Size update on control==>", size , maxSize)
    if(size > maxSize){
      return;
    }
    const response = await client.post('/cm-content/updateCmContent', formData);

    return response.data;



    // const response = await client.post('/cm-content/updateCmContent', data);

    // return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const activeCmContents = async (data) => {
  try {
    const response = await client.post('/cm-content/activeCmContents', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const inActiveCmContents = async (data) => {
  try {
    const response = await client.post('/cm-content/inActiveCmContents', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const deleteCmContents = async (data) => {
  try {
    const response = await client.post('/cm-content/deleteCmContents', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}