import { Constants } from '../views/customerSurvey/constants';
import { createBy, updateBy } from 'utils/apiHelper'
import client from './instance'
import client2 from './instanceNoLodding'

export const getPermission = async (data) => {
  try {
    console.log('getPermission data=',data);
    const response = await client2.post('/customer-surveys/permission', data);
    console.log('getPermission Response =',response);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const redeemReward = async (data) => {
  try {
    console.log('redeemReward data=',data);
    const response = await client2.post('/customer-surveys/redeem-reward', data);
    console.log('redeemReward Response =',response);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const save = async (data) => {
  try {
    console.log('save - save data=',data);
    let formData = initializedSaveData(data);
    const response = await client2.post('/customer-surveys/save', formData);
    console.log('save Response =',response);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const track = async (data) => {
  try {
    console.log('track - save data=',data);
    let formData = initializedSaveData(data);
    const response = await client2.post('/customer-surveys/save', formData);
    console.log('save Response =',response);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

const initializedSaveData = (data) => {
  const result = new FormData();
    if(data && data.customerSurveyAnswer) {
      let newData = {
        ...data,
        customerSurveyAnswer: data.customerSurveyAnswer ? data.customerSurveyAnswer.map((element) => {
            console.log('element', element);
            console.log('element.answerType', element.answerType);
            if(Constants.SURVEY_TYPE.IMAGE == element.answerType) {
            console.log('Survey type image found.');

            const newAnswerKey = 'image' + element.surveyQuestion + '_' + element.surveyQuestionAnswer;
            result.append(newAnswerKey, element.answer);
            
            const newAnswerObject = {
              ...element,
              answer: newAnswerKey,
            }
            console.log(`newAnswerKey=${newAnswerKey} : newAnswerObject=`,newAnswerObject)
            return newAnswerObject;
          } else {
            return element;
          }
        }) : []
      }
      result.append("customerSurvey", JSON.stringify(newData));
    } else {
      result.append("customerSurvey", JSON.stringify(data));
    }
    return result;
}

export const getCustomerSurvey = async (data) => {
  try {
    console.log('getCustomerSurvey data=',data);
    const response = await client2.post('/customer-surveys/get-customer-survey', data);
    console.log('getCustomerSurvey Response =',response);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getRewardMessage = async (data) => {
  try {
    console.log('getRewardMessage data=',data);
    const response = await client2.post('/customer-surveys/get-reward-message', data);
    console.log('getRewardMessage Response =',response);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

