import client from './instance'
import { createBy, updateBy } from 'utils/apiHelper'

export const getCmTheOneCampaigns = async (data) => {
  try {
    const response = await client.post('/cm-the-one-campaign/getCmTheOneCampaigns', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getCmTheOneCampaignBy = async (data) => {
  try {
    const response = await client.post('/cm-the-one-campaign/getCmTheOneCampaignBy', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getCmTheOneCampaignById = async (id) => {
  try {
    const response = await client.post('/cm-the-one-campaign/getCmTheOneCampaignById', { id });
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const haveReferenceCmTheOneCampaign = async (id) => {
  try {
    const response = await client.post('/cm-the-one-campaign/haveReferenceCmTheOneCampaign', { id });
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const createCmTheOneCampaign = async (data) => {
  try {
    const response = await client.post('/cm-the-one-campaign/createCmTheOneCampaign', createBy(data));
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const updateCmTheOneCampaign = async (data) => {
  try {
    const response = await client.post('/cm-the-one-campaign/updateCmTheOneCampaign', updateBy(data));
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const activeCmTheOneCampaigns = async (data) => {
  try {
    const response = await client.post('/cm-the-one-campaign/activeCmTheOneCampaigns', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const inActiveCmTheOneCampaigns = async (data) => {
  try {
    const response = await client.post('/cm-the-one-campaign/inActiveCmTheOneCampaigns', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const deleteCmTheOneCampaigns = async (data) => {
  try {
    const response = await client.post('/cm-the-one-campaign/deleteCmTheOneCampaigns', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}