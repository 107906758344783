import client from './instance'
import { createBy, updateBy } from 'utils/apiHelper'
import { isFile } from 'utils/miscHelper'
import client2 from './instanceNoLodding'
 

export const getCnfParameters = async (data) => {
  try {
    const response = await client.post('/cnf-parameter/getCnfParameters2', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getCnfParametersList = async (data) => {
  try {
    const response = await client.post('/cnf-parameter/getCnfParametersList', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getCnfParameterById = async (id) => {
  try {
    const response = await client.post('/cnf-parameter/getCnfParameterById', { id });

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const fetchParameterTemp = async (data) => {
  try {
    const response = await client2.post('/cnf-parameter/fetchParameterTemp', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getCnfParameterBy = async (data) => {
  try {
    const response = await client2.post('/cnf-parameter/getCnfParameterBy', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getCnfParameterByNoCache = async (data) => {
  try {
    const response = await client2.post('/cnf-parameter/getCnfParameterByNoCache', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}


export const createCnfParameter = async (data) => {
  try {
    const response = await client.post('/cnf-parameter/createCnfParameter', createBy(data));

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const updateCnfParameter = async (data) => {
  try {
    const response = await client.post('/cnf-parameter/updateCnfParameter', updateBy(data));

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const activeCnfParameters = async (data) => {
  try {
    const response = await client.post('/cnf-parameter/activeCnfParameters', createBy(data));

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}


export const inActiveCnfParameters = async (data) => {
  try {
    const response = await client.post('/cnf-parameter/inActiveCnfParameters', updateBy(data));

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const deleteCnfParameters = async (data) => {
  try {
    const response = await client.post('/cnf-parameter/deleteCnfParameters', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}