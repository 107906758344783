import store from 'store'
import axios from 'axios'
import config from './config'
import Swal from 'sweetalert2'
import client from './instance'
const { auth } = store.getState()
const instanceNoLodding  = axios.create({
    baseURL: config.API_BASE_URL,
})

instanceNoLodding.interceptors.request.use(
    (config) => {
        const token = auth.accessToken;
        if (token) {
            config.headers['x-access-token'] = token;
        }
        return config;
    }, function (error) {
        return Promise.reject(error);
    }
)

instanceNoLodding.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response) {
            console.error('Response error:', error.response);
            if (error.response?.status === 401) {
                localStorage.clear()
                client.redirectLoginCas()
            }
        } else {
            console.error('Network error:', error);
        }
        return Promise.reject(error);
    }
);

export default instanceNoLodding;
